import {
  HeadingWithCapsize,
  type HeadingWithCapsizeProps,
} from "@tokenterminal/ui/Heading/Heading"
import {
  baseMarketingHeadingCss,
  textLCss,
  textMCss,
  textSCss,
  textXsCss,
} from "./MarketingHeading.css"

const MarketingHeading = ({
  className,
  level,
  ...props
}: HeadingWithCapsizeProps) => {
  return (
    <HeadingWithCapsize
      className={[
        baseMarketingHeadingCss,
        {
          [textLCss]: level === 1,
          [textMCss]: level === 2,
          [textSCss]: level === 3,
          [textXsCss]: level === 4,
        },
        className,
      ]}
      level={level}
      {...props}
    />
  )
}

export default MarketingHeading
